import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * The following constants and type declarations define our container-width override system:
 * NO_OVERRIDE - means we use the default layout width.
 * VALID_CONTAINER_WIDTHS - array of our valid override widths
 * ContainerWidthOverride - a union type derived from VALID_CONTAINER_WIDTHS
 */
export const NO_OVERRIDE = 'none' as const;
export const VALID_CONTAINER_WIDTHS = [
  NO_OVERRIDE,
  '400',
  '420',
  '600',
  '800',
] as const;
export type ContainerWidthOverride = (typeof VALID_CONTAINER_WIDTHS)[number];

/**
 * LayoutService is responsible for managing container-width overrides across the application.
 * To add a new container width override:
 * 1. Add the new width to VALID_CONTAINER_WIDTHS.
 * 2. Create a new CSS class (e.g., "container-override-400") in app.component.scss.
 * 3. Use the new width in the route data 'containerWidth' property.
 */
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  // BehaviorSubject to store the current width override
  private overrideWidthSubject = new BehaviorSubject<ContainerWidthOverride>(
    NO_OVERRIDE,
  );

  // Observable to emit the active container width override whenever it changes
  overrideWidth$ = this.overrideWidthSubject.asObservable();

  // type guard to check if a value is a valid container width
  isContainerWidthOverride(value: unknown): value is ContainerWidthOverride {
    return (VALID_CONTAINER_WIDTHS as readonly string[]).includes(
      value as string,
    );
  }

  // set the container width override
  setOverrideWidth(width: ContainerWidthOverride): void {
    this.overrideWidthSubject.next(width);
  }

  // clear the container width override
  clearOverrideWidth(): void {
    this.overrideWidthSubject.next(NO_OVERRIDE);
  }
}
