import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { NavService } from './core/services/nav.service';
import { AppInitService } from './app-init.service';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { ViewTitleHeaderComponent } from './shared/components/view-title-header/view-title-header.component';
import { ShortMessageComponent } from './shared/components/short-message/short-message.component';
import { PracticeHeaderComponent } from './shared/components/practice-header/practice-header.component';
import { NavSchedulingService } from './scheduler/services/nav-scheduling.service';
import { NgIf } from '@angular/common';
import { AppConfigService } from './core/services/app-config.service';
import { Subject, takeUntil } from 'rxjs';
import {
  ContainerWidthOverride,
  LayoutService,
  NO_OVERRIDE,
  VALID_CONTAINER_WIDTHS,
} from './shared/layout.service';
import { filter, map } from 'rxjs/operators';

/**
 * The AppComponent is the root component of the application.
 * It initializes the application, manages navigation, and handles layout adjustments.
 *
 * Key responsibilities:
 * - Initializes the application and navigates to the first route.
 * - Subscribes to route changes to manage container width overrides.
 * - Applies container width overrides to the DOM.
 * - Disables external stylesheets to avoid conflicts with application styles.
 * - Manages the view title for the current route.
 */
@Component({
  selector: 'eos-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    PracticeHeaderComponent,
    ShortMessageComponent,
    ViewTitleHeaderComponent,
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  // This feeds the title of the current view to the ViewTitleHeaderComponent
  viewTitle = '';
  private destroy$ = new Subject<void>();

  // store the last known desired width from the LayoutService
  private desiredWidth: ContainerWidthOverride = NO_OVERRIDE;

  constructor(
    private logger: NGXLogger,
    private navService: NavService,
    private navSchedulingService: NavSchedulingService,
    private appInitService: AppInitService,
    private appConfigService: AppConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private layoutService: LayoutService,
    private renderer: Renderer2,
  ) {}

  // We don't come here until app-init.service.ts has finished initializing.
  ngOnInit(): void {
    this.logger.info('AppComponent initialized');
    if (this.appInitService.getInitialized()) {
      // Disable external stylesheets
      this.disableExternalStylesheets();
      this.navService.navigateToPage(this.navSchedulingService.getFirstRoute());
    } else {
      this.navService.navigateToErrorPage();
    }

    // Subscribe to viewTitle observable. It is updated by each view component.
    this.appConfigService.getViewTitle$().subscribe((data) => {
      setTimeout(() => {
        this.viewTitle = data;
      });
    });

    // Subscribe to route changes and read 'containerWidth' from route data
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          // Walk down to the deepest child route
          let child = this.route.firstChild;
          while (child?.firstChild) {
            child = child.firstChild;
          }
          // Return route data (could be undefined if missing)
          return child?.snapshot.data?.['containerWidth'];
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((widthOverride) => {
        if (!widthOverride) {
          // No containerWidth => revert to default
          this.layoutService.clearOverrideWidth();
        } else if (this.layoutService.isContainerWidthOverride(widthOverride)) {
          // If it's valid, set the override
          this.layoutService.setOverrideWidth(widthOverride);
        } else {
          // It's a string but not in VALID_CONTAINER_WIDTHS
          this.logger.warn(
            'Invalid containerWidth in route data:',
            widthOverride,
          );
          this.layoutService.clearOverrideWidth();
        }
      });

    // Subscribe to the LayoutService and apply the desired width override
    this.layoutService.overrideWidth$
      .pipe(takeUntil(this.destroy$))
      .subscribe((width: ContainerWidthOverride) => {
        // Just store it; we'll apply it later in ngAfterViewChecked
        this.desiredWidth = width;
      });
  }

  // Called *after* Angular has done its checks for the current change detection cycle.
  // That means the DOM (including any *ngIf elements) should be updated by now.
  ngAfterViewChecked(): void {
    // Apply the desired width override to the DOM
    this.applyContainerWidthClass(this.desiredWidth);
  }

  // We need to disable stylesheets loaded from index.html to avoid conflicts
  // with styles being used by the application
  private disableExternalStylesheets(): void {
    const allStylesheets = document.styleSheets;

    // Filter stylesheets containing 'material' in their href
    const externalStylesheets = Array.from(allStylesheets).filter(
      (sheet) =>
        sheet.href &&
        (sheet.href.includes('googleapis.com') ||
          sheet.href.includes('getmdl.io')),
    );

    // Disable the matching stylesheets
    externalStylesheets.forEach((sheet) => {
      this.logger.debug('Disabling stylesheet:', sheet.href);
      sheet.disabled = true;
    });
  }

  // Applies or removes container-override classes based on width
  private applyContainerWidthClass(width: ContainerWidthOverride): void {
    const viewTitleElement = document.getElementById('view-title');
    const routerOutletElement = document.getElementById(
      'router-outlet-content',
    );
    if (!viewTitleElement || !routerOutletElement) {
      return;
    }

    // Remove all known overrides first
    for (const w of VALID_CONTAINER_WIDTHS) {
      if (w === NO_OVERRIDE) {
        // We don't have a 'container-override-none' class in CSS, so skip it
        continue;
      }
      const className = `container-override-${w}`;
      this.renderer.removeClass(viewTitleElement, className);
      this.renderer.removeClass(routerOutletElement, className);
    }

    // If we have a specific override (not 'none'), apply it
    if (width !== NO_OVERRIDE) {
      const overrideClass = `container-override-${width}`;
      if (this.isValidCssClass(overrideClass)) {
        this.renderer.addClass(viewTitleElement, overrideClass);
        this.renderer.addClass(routerOutletElement, overrideClass);
      } else {
        this.logger.warn(
          `CSS class ${overrideClass} not found. Do you need to add it to app.component.scss?`,
        );
      }
    }
  }

  private isValidCssClass(className: string): boolean {
    const target = '.' + className;

    for (const sheet of Array.from(document.styleSheets)) {
      let rules: CSSRuleList;
      try {
        // Cross-origin stylesheets may throw an error
        rules = (sheet as CSSStyleSheet).cssRules;
      } catch {
        // Skip weirdos
        continue;
      }

      for (const rule of Array.from(rules)) {
        if (
          rule instanceof CSSStyleRule &&
          rule.selectorText.includes(target)
        ) {
          return true;
        }
      }
    }

    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
