import { Injectable } from '@angular/core';
import { catchError, from, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { map, shareReplay, tap } from 'rxjs/operators';
import {
  ApiAppointment,
  ApiPatientAppointmentResult,
} from '../../shared/domains/scheduler/api-appointment';
import { Portal } from '../../shared/domains/scheduler/portal';
import { environment } from '../../../environments/environment';
import { Scheduler } from '../../shared/domains/scheduler/scheduler';
import { AppointmentRequestPatient } from '../../shared/domains/scheduler/appointment-request-patient';
import { PatientSearchResult } from '../../shared/domains/scheduler/patient-search/patient-search-result';
import { ApiAvailableAppointments } from '../../shared/model/appointment.model';
import { NavService } from '../services/nav.service';
import { DynamicFormConfig, DynamicFormContent } from 'openapi';
import { AppointmentV2 } from 'openapi';

@Injectable({
  providedIn: 'root',
})
export class ApiQueryService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private navService: NavService,
  ) {}

  private handleError(error: HttpErrorResponse): Observable<never> {
    this.logger.error('ApiQueryService: An HTTP error occurred:', error);
    this.logger.error('ApiQueryService: Navigating to error page');
    this.navService.navigateToErrorPage();
    return throwError(() => error);
  }

  fetchPortal(domainName: string): Observable<Portal> {
    return this.http
      .get<Portal>(`${environment.apiUrl}portals?domain_name=${domainName}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  fetchScheduler(link: string): Observable<Scheduler> {
    return this.http
      .get<Scheduler>(link)
      .pipe(catchError(this.handleError.bind(this)));
  }

  putAddBookAppointment$(
    link: string,
    appointment: ApiAppointment,
  ): Observable<ApiAppointment> {
    this.logger.debug('in ApiQueryService.putAddBookAppointment$', link);
    return this.http
      .put<ApiAppointment>(link, appointment)
      .pipe(catchError(this.handleError.bind(this)));
  }

  putUpdateConfirmedAppointment$(
    link: string,
    appointment: ApiAppointment,
  ): Observable<ApiAppointment> {
    this.logger.debug(
      'in ApiQueryService.putUpdateConfirmedAppointment$',
      link,
    );
    return this.http
      .put<ApiAppointment>(link, appointment)
      .pipe(catchError(this.handleError.bind(this)));
  }

  postAddUnconfirmedAppointment(
    link: string,
    appointment: ApiAppointment,
  ): Observable<ApiAppointment> {
    this.logger.debug('in ApiQueryService.postAddUnconfirmedAppointment', link);
    return this.http
      .post<ApiAppointment>(link, appointment)
      .pipe(catchError(this.handleError.bind(this)));
  }

  patientSearch(
    patientSearchLink: string,
    patient: AppointmentRequestPatient,
  ): Observable<PatientSearchResult> {
    this.logger.debug('ApiQueryService.patientSearch');
    return this.http
      .post<PatientSearchResult>(patientSearchLink, patient)
      .pipe(catchError(this.handleError.bind(this)));
  }

  fetchAvailableAppointments(
    link: string,
  ): Observable<ApiAvailableAppointments> {
    this.logger.debug('ApiQueryService.fetchAvailableAppointments');
    return this.http
      .get<ApiAvailableAppointments>(link)
      .pipe(shareReplay(), catchError(this.handleError.bind(this)));
  }

  fetchPatientAppointments(
    link: string,
  ): Observable<ApiPatientAppointmentResult[]> {
    this.logger.debug('ApiQueryService.fetchPatientAppointments');
    return this.http
      .get<ApiPatientAppointmentResult[]>(link)
      .pipe(catchError(this.handleError.bind(this)));
  }

  fetchPatientPreForms(link: string): Observable<DynamicFormConfig[]> {
    this.logger.debug('ApiQueryService.fetchPatientPreForms');
    return this.http
      .get<DynamicFormConfig[]>(link)
      .pipe(catchError(this.handleError.bind(this)));
  }

  /*
  https://api-staging.eyefinity.com/mt-pe-portal-api/
  forms/v2/8b6dcb4720e846a0be13e2a287be41f6/
  forms/3D%20OCT%20Scan/
  patient/5f3557a3-9103-438a-b6bf-3a62465e52dc?locationId=null&lastSubmitted=false{&formId}
   */
  fetchPatientFormData(link: string): Observable<DynamicFormContent> {
    this.logger.debug('ApiQueryService.fetchPatientFormData');
    return this.http
      .get<DynamicFormContent>(link)
      .pipe(catchError(this.handleError.bind(this)));
  }

  appointmentReschedule(
    link: string,
    apptReqObj: AppointmentV2,
  ): Observable<void> {
    this.logger.debug(
      'ApiQueryService.appointmentReschedule',
      link,
      apptReqObj,
    );
    return this.http
      .put<void>(link, apptReqObj)
      .pipe(catchError(this.handleError.bind(this)));
    // To emulate a successful response, return of(void 0)
  }

  appointmentCancel(link: string): Observable<void> {
    this.logger.debug('ApiQueryService.appointmentCancel');
    return this.http
      .delete<void>(link)
      .pipe(catchError(this.handleError.bind(this)));
    // To emulate a successful response, return of(void 0)
  }

  fetchPatientFormDataFromFile(_link: string): Observable<DynamicFormContent> {
    this.logger.debug('ApiQueryService.fetchPatientPreFormsFromFile');

    // return from(import('../../forms/pre-visit-form.json')).pipe(
    return from(import('../../forms/step1.json')).pipe(
      map((data) => data as unknown as DynamicFormContent),
      catchError(this.handleError.bind(this)),
    );
  }

  fetchPatientFormIndexFromFile(
    _link: string,
  ): Observable<DynamicFormConfig[]> {
    this.logger.debug('ApiQueryService.fetchPatientPreFormsFromFile');

    return from(import('../../forms/form-index.json')).pipe(
      tap((data) => console.log('Imported data:', data)),
      map((data) => data as unknown as DynamicFormConfig[]),
      catchError(this.handleError.bind(this)),
    );
  }

  savePatientDynamicForm(
    link: string,
    requestBody: DynamicFormContent,
  ): Observable<DynamicFormContent> {
    this.logger.debug('ApiQueryService.savePatientDynamicFrom');

    return this.http
      .post<DynamicFormContent>(link, requestBody)
      .pipe(catchError(this.handleError.bind(this)));
    // To emulate a successful response, return of(requestBody)
  }
}
