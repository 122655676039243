import { Injectable, NgZone } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  UrlTree,
} from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ERROR_PAGE_URL } from '../../shared/constants';
import { Location } from '@angular/common';

/**
 * Effectively encapsulates the Angular Router and provides a single point of navigation for the application.
 */
@Injectable({
  providedIn: 'root',
})
export class NavService {
  private routerLogging = false;

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private ngZone: NgZone,
    private location: Location,
  ) {
    if (this.routerLogging) {
      this.enableRouterLogging();
    }
  }

  navigateToErrorPage(): void {
    this.logger.debug('NavService.navigateToErrorPage called', ERROR_PAGE_URL);
    this.ngZone.run(() => {
      this.router.navigate([ERROR_PAGE_URL]).then(
        (success) => {
          if (success) {
            this.logger.debug(
              'Navigation to error page successful',
              ERROR_PAGE_URL,
            );
          } else {
            this.logger.error(
              'Navigation to error page failed',
              ERROR_PAGE_URL,
            );
          }
        },
        (error) => {
          this.logger.error('Navigation error (navigateToErrorPage)', error);
        },
      );
    });
  }

  navigateToPage(url: string | UrlTree): void {
    this.logger.debug('NavService.navigateToPage called', url);
    this.ngZone.run(() => {
      this.router.navigateByUrl(url).then(
        (success) => {
          if (success) {
            this.logger.debug('Navigation to page successful', url);
          } else {
            this.logger.warn('Navigation to page failed', url);
            this.navigateToErrorPage();
          }
        },
        (error) => {
          this.logger.error('Navigation error (navigateToPage)', error, url);
          this.navigateToErrorPage();
        },
      );
    });
  }

  navigateBack(): void {
    this.logger.debug('NavService.navigateBack called');
    this.location.back();
  }

  /* istanbul ignore next */
  private enableRouterLogging(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.logger.debug('Router: NavigationStart', event.url);
      } else if (event instanceof NavigationEnd) {
        this.logger.debug('Router: NavigationEnd', event.url);
      } else if (event instanceof NavigationError) {
        this.logger.error('Router: NavigationError', event.error);
      } else if (event instanceof NavigationCancel) {
        this.logger.warn('Router: NavigationCancel', event.url);
      }
    });
  }
}
