export const schedulerPath = 'scheduler/';

export enum RoutePage {
  LANDING_PAGE = 'landing-page',
  SITE_MESSAGE = 'site-message',
  LOCATION_LAYOUT = 'location-layout',
  SERVICE_VIEW = 'service-view',
  CONFIRM_DATE_TIME = 'confirm-date-time',
  PATIENT_INFO = 'patient-info',
  CONFIRMATION_VIEW = 'confirmation-view',
  UPCOMING = 'upcoming',
  PATIENT_SEARCH = 'patient-search',
  DYNAMIC_FORM = 'dynamic-form',
}

export enum RoutePath {
  LANDING_PAGE = schedulerPath + RoutePage.LANDING_PAGE,
  SITE_MESSAGE = schedulerPath + RoutePage.SITE_MESSAGE,
  LOCATION_LAYOUT = schedulerPath + RoutePage.LOCATION_LAYOUT,
  SERVICE_VIEW = schedulerPath + RoutePage.SERVICE_VIEW,
  CONFIRM_DATE_TIME = schedulerPath + RoutePage.CONFIRM_DATE_TIME,
  PATIENT_INFO = schedulerPath + RoutePage.PATIENT_INFO,
  CONFIRMATION_VIEW = schedulerPath + RoutePage.CONFIRMATION_VIEW,
  UPCOMING = schedulerPath + RoutePage.UPCOMING,
  PATIENT_SEARCH = schedulerPath + RoutePage.PATIENT_SEARCH,
  DYNAMIC_FORM = schedulerPath + RoutePage.DYNAMIC_FORM,
}

export function getRoutePath(routePath: RoutePage): string {
  return `${schedulerPath}/${routePath}`;
}
